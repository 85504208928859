import http from '@/plugins/http-common'
import {OrderByVariants} from './constants'
import {ResponseData} from './types'
import {General, Passport} from '@/composables/agents/agents-models'

export type GetListParams = {
    limit: null | number,
    offset: null | number,
    order_by: null | OrderByVariants,
    sort_by: null | string,
    has_verified_passport: null | boolean,
    is_banned: null | boolean,
    is_waiting_verification: null | string | boolean,
    is_watched: null | boolean,
    is_employer: null | boolean,
    parent_id: null | number,
    role_id: null | number,
    search_fio: null | string,
    search_email: null | string,
    search_ref: null | string,
    search_promo: null | string,
    search_phone: null | string,
    search_inn: null | string,
}


class AgentsService {
    getList = (params: GetListParams): Promise<ResponseData> => http.get('/agents', {params})

    getById = (agent: number): Promise<ResponseData> => http.get(`/agents/${agent}`)

    getComments = (agent: number, limit?: number, offset?: number, order_by?: OrderByVariants, sort_by?: string): Promise<ResponseData> =>
        http.get(`/agents/${agent}/comments`, {params: {agent, limit, offset, order_by, sort_by}})

    getHistory = (agent: number, limit?: number, offset?: number, order_by?: OrderByVariants, sort_by?: string): Promise<ResponseData> =>
        http.get(`/agents/${agent}/history`, {params: {agent, limit, offset, order_by, sort_by}})

    getOrders = (agent: number, limit?: number, offset?: number, order_by?: OrderByVariants, sort_by?: string): Promise<ResponseData> =>
        http.get(`/agents/${agent}/orders`, {params: {agent, limit, offset, order_by, sort_by}})

    addComment = (agent: number, text: string): Promise<ResponseData> =>
        http.post(`/agents/${agent}/comments`, {text})

    ban = (agent: number): Promise<ResponseData> =>
        http.post(`/agents/${agent}/ban`, {agent})

    watch = (agent: number): Promise<ResponseData> =>
        http.post(`/agents/${agent}/watch`, {agent})

    verify = (agent: number,flag: boolean): Promise<ResponseData> =>
        http.post(`/agents/${agent}/passport/verify`, {agent,flag})

    updatePass = (agent: number,data: Passport) : Promise<ResponseData> =>
        http.post(`/agents/${agent}/passport/update`,data)

    updateEmail = (agent: number,data: string) : Promise<ResponseData> =>
        http.post(`/agents/${agent}/email/update`,{email: data})

    spam = (agent: number,flag:boolean) : Promise<ResponseData> =>
        http.post(`/agents/${agent}/spam`, {agent,flag})


}

export default new AgentsService()
